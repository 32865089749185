<template>
  <div class="setting-main-wrapper">
    <div class="shop-side-auth">
      <div class="privacy-policy-page mb-3">
        <div class="back-to-login-page">
          <router-link to="/login">Back to Login</router-link>
        </div>
        <TermsTop />
        <Definitions />
      </div>
    </div>
  </div>
</template>

<script>
import TermsTop from "../components/TermsAndConditions/TermsTop.vue";
import Definitions from "../components/TermsAndConditions/Definitions.vue";

export default {
  components: {
    TermsTop,
    Definitions,
  },
  name: "OurTermsAndConditions",
};
</script>
